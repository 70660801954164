<template>
  <div class="container" @dblclick="clickHandler($event)">

    <FileLoading :showGifImg="showGifImg"></FileLoading>
    <FileAttempt :viewFileAttempt="viewFileAttempt"></FileAttempt>

    <h1>合同详情</h1>
    <div class="row">
      <div class="card" style="width:50%">

        <center>
        <img src="@/assets/invalid.jpeg" width="25%" v-show="contract.status == '作废'"/>
      </center>
        <ul class="timeline">
          <li>
            <div v-if="phase >= contractProcedure.length + 5 && contract.receiptAmount == contract.amount" class="timeline-badge success">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <div v-else class="timeline-badge warning">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="timeline-title">
              <h5>合同完成</h5>
            </div>
          </li>

          <li v-show="phase >= contractProcedure.length + 5 && contract.receiptAmount < contract.amount">
            <div class="timeline-badge warning">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-title">
                <h5>开票完成</h5>
              </div>
            </div>
          </li>

          <li>
            <div class="timeline-badge success" v-if="phase >= contractProcedure.length + 5">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <div class="timeline-badge warning" v-else>
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="timeline-title">
              <h5>{{ payOrReceive }}款完成</h5>
            </div>
          </li>

          <li v-show="phase < contractProcedure.length + 5 || contract.receiptAmount == contract.amount">
            <div class="timeline-badge success" v-if="phase >= contractProcedure.length + 4">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <div class="timeline-badge warning" v-else>
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-title">
                <h5>开票完成</h5>
              </div>
            </div>
          </li>

          <li>
            <div v-if="phase >= contractProcedure.length + 3" class="timeline-badge success">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <div v-else class="timeline-badge warning">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="timeline-title">
              <h5>盖章合同已上传</h5>
            </div>
          </li>
          <li>
            <div v-if="phase >= contractProcedure.length + 2" class="timeline-badge success">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <div v-else class="timeline-badge warning">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="timeline-title">
              <h5>
                合同审批已完成
                <TimelineHover
                v-if="phase >= contractProcedure.length + 2"
                style="display: inline-block;"
                :timelineId="this.$route.params.id" :timestamps="procedureTimestamp">
              </TimelineHover>
              </h5>
            </div>
          </li>

          <li v-if="phase < contractProcedure.length + 2" v-for="(status, index) in contractProcedure" :key="index">
            <div v-if="phase >= contractProcedure.length - index - 1 + 2" class="timeline-badge success">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <div v-else class="timeline-badge warning">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="timeline-title">
              <h5>合同审批已上传至{{ contractProcedure[contractProcedure.length - index - 1] }}
                <WhoIsWho v-show="contractProcedure[contractProcedure.length - index - 1] != '完成'"
                :id="contractProcedure[contractProcedure.length - index - 1]"
                :role="contractProcedure[contractProcedure.length - index - 1]">
              </WhoIsWho>
              <p>{{ !procedureTimestamp ? '' :
                procedureTimestamp[contractProcedure[contractProcedure.length - index - 1]] != null ?
                getFormattedDate(procedureTimestamp[contractProcedure[contractProcedure.length - index - 1]]) : '' }}
              </p>
            </h5>
          </div>
        </li>

        <li v-if="phase < contractProcedure.length + 2">
          <div v-if="phase >= 1" class="timeline-badge success">
            <i class="fa fa-check" aria-hidden="true"></i>
          </div>
          <div v-else class="timeline-badge warning">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
          <div class="timeline-title">
            <h5>合同已上传
              <p>{{ procedureTimestamp == null ? '' : getFormattedDate(procedureTimestamp[contract.owner]) }}</p>
            </h5>
          </div>
        </li>
      </ul>
    </div>
    <div class="card" style="width:50%; overflow-x: auto">
      <table class="table table-hover">
        <tbody>
          <tr>
            <th scope="row" style="min-width: 130px">合同编号</th>
            <td>{{ contract._id }}</td>
          </tr>
          <tr>
            <th scope="row">合同类别</th>
            <td>{{ contract.category }}</td>
          </tr>
          <tr v-show="contract.status != '作废'">
            <th scope="row">项目名称</th>
            <td v-if="contract.projectTitle != '无'">
              <router-link :to="{ name: 'ProjectInfo', params: { id: contract.projectId } }">{{ contract.projectTitle }}</router-link>
            </td>
            <td v-else>{{ contract.projectTitle }}</td>
          </tr>
          <tr v-show="contract.status != '作废'">
            <th scope="row">甲方</th>
            <td>{{ contract.projectCustomer }}</td>
          </tr>
          <tr v-if="contract.category == '采购合同' && contract.status != '作废'">
            <th scope="row">供应商</th>
            <td>
              {{ this.supplier }}
              <SupplierPopover :name="this.supplier" :key="uniqueKey"></SupplierPopover>
            </td>
          </tr>
          <tr>
            <th scope="row">合同负责人</th>
            <td>{{ contract.owner }}</td>
          </tr>
          <tr>
            <th scope="row">合同金额</th>
            <td>{{ contract.amount }}</td>
          </tr>
          <tr>
            <th scope="row">质保期</th>
            <td>{{ contract.warranty > 0 ? contract.warranty : 0 }}年</td>
          </tr>
          <tr>
            <th scope="row">{{ payOrReceive }}款方式</th>
            <td id="paymentMethod" v-if="!enablePaymentMethodEdit">{{ contract.paymentMethod }}</td>
            <input :id="paymentSchedule" v-else v-model="contract.paymentMethod" v-on:keyup.enter="updatePaymentSchedule(), enablePaymentMethodEdit=false">
          </tr>
          <tr v-show="contract.status != '作废'">
            <th scope="row">{{ payOrReceive }}款时间节点</th>
            <td v-if="contract.paymentSchedule != null && contract.paymentSchedule.schedule.length == contract.paymentMethod.split('-').length">
              <ul v-for="(time, index) in contract.paymentSchedule.schedule">
                <li :id="'paymentSchedule'+index" v-if="!enablePaymentScheduleEdit">{{ getYearMonthDate(time) }}</li>
                <b-form-datepicker
                :id="'paymentScheduleInput'+index"
                v-else
                v-model="contract.paymentSchedule.schedule[index]"
                :placeholder="getYearMonthDate(time)"
                locale="zh"
                calendar-width="450px"
                required="required">
                </b-form-datepicker>
              </ul>
              <button class="btn btn-primary" v-show="enablePaymentScheduleEdit" @click="updatePaymentSchedule(), enablePaymentScheduleEdit=false">确定修改</button>
            </td>
            <td v-else>
              <ul v-for="index in paymentScheduleCount">
                <b-form-datepicker v-model="schedule[index]" locale="zh" calendar-width="450px"></b-form-datepicker>
              </ul>
              <button class="btn btn-primary" @click="updatePaymentSchedule()">确定</button>
            </td>
          </tr>
          <tr v-show="contract.status != '作废'">
            <th scope="row">{{ payOrReceive }}款时间节点说明</th>
            <td v-if="contract.paymentSchedule != null">
              <a id="paymentScheduleNote"
              v-if="!enablePaymentScheduleNoteEdit && contract.paymentSchedule != null && contract.paymentSchedule.note != null">
              {{ contract.paymentSchedule.note }}
            </a>
              <input id="paymentScheduleNoteInput"
              v-else
              v-model="contract.paymentSchedule.note"
              v-on:keyup.enter="updatePaymentSchedule(), enablePaymentScheduleNoteEdit=false">
            </td>
          </tr>
          <tr>
            <th scope="row">销售合同</th>
            <td><a href="#" @click="viewFile(contract.fileId, contract.filename)">{{ contract.filename }}</a></td>
          </tr>
          <tr>
            <th scope="row">盖章销售合同</th>
            <td><a href="#" @click="viewFile(contract.stampedFileId, contract.stampedFilename)">{{ contract.stampedFilename }}</a></td>
          </tr>
          <tr>
            <th scope="row">上传日期</th>
            <td>{{ getFormattedDate(contract.uploadTime) }}</td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>


  <br />
  <br />
  <br />

  <h1>{{ payOrReceive }}款情况</h1>

  <div class="row">
    <div class="card" style="width:50%">
      <canvas id="planet-chart"></canvas>
    </div>
    <div class="card" style="width:50%; overflow-x: auto">
      <table class="table table-hover">
        <thead>
          <tr class="text-center">
            <th class="align-middle" style="min-width: 100px">{{ payOrReceive }}款金额</th>
            <th class="align-middle" style="min-width: 140px">{{ payOrReceive == '付' ? '计划' : ''}}{{ payOrReceive }}款日期</th>
            <th class="align-middle" v-if="contract.category == '采购合同'" style="min-width: 100px">付款进度</th>
            <th class="align-middle" v-else></th>
            <th class="align-middle" style="min-width: 150px">备注</th>
            <th>付款信息</th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="payment in payments" :key="payment._id" class="text-center">
            <td class="align-middle">{{ payment.amount }}</td>
            <td class="align-middle">{{ getFormattedDate(payment.date) }}</td>
            <td class="align-middle" v-if="contract.category == '采购合同'">
              {{ payment.procedure }}
              <WhoIsWho
              :id="payment._id + 'WhoIsWho'"
              :role="payment.procedure"
              v-if="payment.procedure != '完成' && payment.procedure != '已驳回' && payment.procedure != contract.owner">
            </WhoIsWho>
          </td>
          <td class="align-middle" v-else></td>
          <td class="text-left align-middle" v-html="!payment.note ? '' : payment.note.replace(/\n/, '<br /><br />')"></td>

          <td class="align-middle"><a href="#" @click="viewFile(payment.accountImgId, payment.accountImgFilename)">{{ payment.accountImgFilename }}</a></td>


          <td v-if="payment.procedureTimestamp != null" class="align-middle">
            <TimelineHover :timelineId="payment._id" :timestamps="payment.procedureTimestamp"></TimelineHover>
          </td>
          <td v-else></td>

          <td v-if="payment.procedureNote != null && payment.procedureNote != ''" class="align-middle">
            <ProcedureNote :id="payment._id.toString() + 'ProcedureNote'" :note="payment.procedureNote"></ProcedureNote>
          </td>
          <td v-else></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<br />
<br />
<br />

<h1 v-if="contract.category == '采购合同'">收票情况</h1>
<h1 v-else>开票情况</h1>

<div class="row">
  <div class="card" style="width:50%">
    <canvas id="receipt-chart"></canvas>
  </div>
  <div class="card" style="width:50%; overflow-x: auto">
    <table class="table table-hover" style='overflow-x:auto'>
      <thead>
        <tr class="text-center">
          <th class="align-middle" style="width: 120px">发票</th>
          <th class="align-middle" style="min-width: 100px">发票金额</th>

          <th class="align-middle" v-if="contract.category == '采购合同'" style="min-width: 120px">收票日期</th>
          <th class="align-middle" v-else style="min-width: 120px">开票日期</th>

          <th class="align-middle" v-if="contract.category == '销售合同'" style="min-width: 100px">开票进度</th>
          <th class="align-middle" style="min-width: 150px">备注</th>
          <th></th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="receipt in receipts" :key="receipt._id" class="text-center">
          <td class="align-middle"><a href="#" @click="viewFile(receipt.fileId, receipt.filename)">{{ receipt.filename }}</a></td>
          <td class="align-middle">{{ receipt.amount }}</td>
          <td class="align-middle">{{ getFormattedDate(receipt.date) }}</td>
          <td class="align-middle" v-if="contract.category == '销售合同'">
            {{ receipt.procedure }}
            <WhoIsWho :id="receipt._id + 'WhoIsWho'" :role="receipt.procedure" v-if="receipt.procedure != '完成' && receipt.procedure != contract.owner"></WhoIsWho>
          </td>
          <td class="text-left align-middle" v-html="!receipt.note ? '' : receipt.note.replace(/\n/, '<br /><br />')"></td>

          <td v-if="receipt.procedureTimestamp != null" class="align-middle">
            <TimelineHover :timelineId="receipt._id" :timestamps="receipt.procedureTimestamp"></TimelineHover>
          </td>
          <td v-else></td>

          <td v-if="receipt.procedureNote != null && receipt.procedureNote != ''" class="align-middle">
            <ProcedureNote :id="receipt._id.toString() + 'ProcedureNote'" :note="receipt.procedureNote"></ProcedureNote>
          </td>
          <td v-else></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<br />
<br />
<br />

<div class="card" v-show="items.length > 0 && contract.category == '采购合同'">
  <div style="overflow-x:auto">
    <table class="table table-hover table-sm">
      <thead>
        <tr class="text-center">
          <th class="align-middle">序号</th>
          <Header header="名称" @sort="sort('name')"></Header>
          <Header header="品牌" @sort="sort('brand')"></Header>
          <Header header="型号" @sort="sort('series')"></Header>
          <Header header="单价" @sort="sort('price')"></Header>
          <Header header="数量" @sort="sort('quantity')"></Header>
          <Header header="供应商" @sort="sort('supplier')"></Header>
          <Header header="采购责任人" @sort="sort('owner')"></Header>
          <th style="min-width: 100px"></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="item._id" class="text-center">
          <td class="align-middle"><b>{{ index + 1 }}</b></td>
          <td class="align-middle">{{ item.name }}</td>
          <td class="align-middle">{{ item.brand }}</td>
          <td class="align-middle">{{ item.series }}</td>
          <td class="align-middle">{{ item.price }}</td>
          <td class="align-middle">{{ item.quantity }}</td>
          <td class="align-middle">{{ item.supplier }}</td>
          <td class="align-middle">{{ item.owner }}</td>
          <td class="align-middle">
            <router-link :to="{ name: 'ItemDetails', params: {id: item._id} }" class="btn btn-success btn-sm">详情</router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

</div>
</template>

<script>
import Chart from 'chart.js';
import fileIO from '@/js/fileIO';
import formatTime from '@/js/formatTime';
import storeUtil from '@/js/storeUtil';
import accountingUtil from '@/js/accountingUtil';

export default {
  data() {
    return {
      contract: {},
      payments: [],
      receipts: [],
      phase: '',
      contractProcedure: [],
      procedureTimestamp: {},
      items: [],
      enablePaymentMethodEdit: false,
      enablePaymentScheduleEdit: false,
      enablePaymentScheduleNoteEdit: false,
      schedule: [],
      backgroundColor: [ '#3BB9FF', '#C2DFFF' ],
      chartOption: {
        animation: {
          animateScale: true,
          animateRotate: true
        }
      },
      payOrReceive: '',
      paymentScheduleCount: [],
      supplier: '',
      uniqueKey: 0
    }
  },
  mixins: [
    fileIO, formatTime, storeUtil,  accountingUtil
  ],
  created() {
    this.fetchContract();
    this.fetchProcedureTimestamp();
  },
  methods: {
    fetchContract() {
      this.axios.post(`overall/${this.getLocation}/contracts/project/title`, {
        _id: this.$route.params.id
      }).then(response => {
        if ((this.getUser.role && this.authLevel(4)) || response.data[0].owner == this.getUser.name) {
          if (response.data[0].paymentSchedule != null) {
            this.contract.paymentSchedule = {};
            this.contract.paymentSchedule.schedule = response.data[0].paymentSchedule.schedule;
          }
          for (var key in response.data[0]) {
            var value = response.data[0][key];
            if (key != 'paymentSchedule') {
              this.contract[key] = value;
            }
          }

          this.payOrReceive = this.contract.category == '销售合同' ? '收' : this.contract.category == '采购合同' ? '付' : '';
          var length = this.contract.paymentMethod.split('-').length;
          var index = 0;
          while (index < length) {
            this.paymentScheduleCount.push(index);
            index++;
          }

          this.getPhase();
          this.getProcedure();

          this.createChart('planet-chart');
          this.createReceiptChart('receipt-chart');
          this.fetchPayments();
          this.fetchReceipts();
          if (this.contract.category == '采购合同') {
            this.fetchItems();
          }
        } else {
          alert('您暂无权查看此页');
          this.$router.go(-1);
        }
      }).catch(err => {
        console.log(err);
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchPayments() {
      this.axios.post(`payments/${this.getLocation}/queryAll/timeline`, {
        contractId: this.$route.params.id
      }).then((response) => {
        this.payments = response.data;
      }).catch(err => {
        console.log(err);
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchReceipts() {
      this.axios.post(`receipts/${this.getLocation}/queryAll/timeline`, {
        contractId: this.$route.params.id
      }).then((response) => {
        this.receipts = response.data;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchProcedureTimestamp() {
      this.axios.get(`redis/hgetall/${this.$route.params.id}`).then((response) => {
        this.procedureTimestamp = response.data[0];
      });
    },
    fetchItems() {
      this.axios.post(`items/${this.getLocation}/queryAll`, {
        contractId: this.$route.params.id
      }).then((response) => {
        this.items = response.data;
        this.supplier = this.items[0].supplier;
        this.rerender();
      });
    },
    createChart(chartId) {
      var label = [
        `已${this.payOrReceive}款 (${(this.contract.paidAmount / this.contract.amount * 100).toFixed(2)}%)`,
        `未${this.payOrReceive}款 (${((this.contract.amount - this.contract.paidAmount) / this.contract.amount * 100).toFixed(2)}%)`
      ];

      const ctx = document.getElementById(chartId);
      var _this = this;
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [ this.contract.paidAmount, this.contract.amount - this.contract.paidAmount ],
            backgroundColor: _this.backgroundColor
          }],
          labels: label
        },
        options: _this.chartOption
      });
    },
    createReceiptChart(chartId) {
      var payOrReceive = this.contract.category == '销售合同' ? '开出' : this.contract.category == '采购合同' ? '收到' : '';
      var label = [
        `已${payOrReceive}发票 (${(this.contract.receiptAmount / this.contract.amount * 100).toFixed(2)}%)`,
        `未${payOrReceive}发票 (${((this.contract.amount - this.contract.receiptAmount) / this.contract.amount * 100).toFixed(2)}%)`
      ];

      var chartData = [ this.contract.receiptAmount, this.contract.amount - this.contract.receiptAmount ];
      const ctx = document.getElementById(chartId);
      var _this = this;
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: chartData,
            backgroundColor: _this.backgroundColor
          }],
          labels: label
        },
        options: _this.chartOption
      });
    },
    getPhase() {
      this.axios.get(`contracts/${this.getLocation}/history/${this.$route.params.id}`).then((response) => {
        this.phase = response.data.phase;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    getProcedure() {
      var procedureName = this.contract.category == '销售合同' ? 'sortedContractProcedure' : this.contract.category == '采购合同' ? 'sortedPurchasingContractProcedure' : '';
      this.axios.get(`redis/zrange/${procedureName}`).then((response) => {
        this.contractProcedure = response.data[0];
      });
    },
    updatePaymentSchedule() {
      if (this.schedule.length > 0) {
        this.contract.paymentSchedule.schedule = this.schedule;
      }
      this.axios.post(`contracts/${this.getLocation}/update/payment/${this.$route.params.id}`, this.contract).then(response => {
        alert(response.data);
        this.$forceUpdate();
      });
    },
    rerender() {
      this.uniqueKey++;
    },
    clickHandler(event) {
      var scheduleLength = 0;
      if (this.contract.paymentSchedule != null && this.contract.paymentSchedule.schedule != null) {
        scheduleLength = this.contract.paymentSchedule.schedule.length;
      }
      var scheduleCount = 0;
      while(scheduleCount < scheduleLength) {
        if (event.target === document.getElementById('paymentSchedule' + scheduleCount)) {
          this.enablePaymentScheduleEdit = true;
          return;
        } else {
          scheduleCount++;
        }
      }
      switch(event.target) {
        case document.getElementById('paymentMethod'):
        this.enablePaymentMethodEdit = true;
        break;
        case document.getElementById('paymentScheduleInput'):
        break;
        case document.getElementById('paymentScheduleNote'):
        this.enablePaymentScheduleNoteEdit = true;
        break;
        case document.getElementById('paymentScheduleNoteInput'):
        break;
        default:
        this.enablePaymentMethodEdit = false;
        this.enablePaymentScheduleEdit = false;
        this.enablePaymentScheduleNoteEdit = false;
      }
    }
  }
}
</script>
